import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ["positive","neutral","negative"]
	positive(event, callback) {
		const dropdown = document.querySelector("#select-tags")
		const taggingId = this.positiveTargets[0].dataset.taggingid
		const sentimentId = this.positiveTargets[0].dataset.sentiment
		const answerId = dropdown.dataset.answeridValue
		const formId = dropdown.dataset.formidValue
		const data = { tagging_id: parseInt(taggingId), sentiment_id: parseInt(sentimentId), id: parseInt(answerId), form_id: parseInt(formId) }
		const token = document.querySelector('meta[name="csrf-token"]').content
		fetch('/sentiments', {
			method: 'PUT',
			headers:  {
				"X-CSRF-Token": token,
				"Content-Type": "application/json",
				"Accept": "application/json"
			},
			body: JSON.stringify(data)
		})
		.then((response) => {
			return response.json();
		})
		.then((data) => {
			callback({value: data.id, text: data.name })
		});
	}
	neutral(event, callback) {
		const dropdown = document.querySelector("#select-tags")
		const taggingId = this.neutralTargets[0].dataset.taggingid
		const sentimentId = this.neutralTargets[0].dataset.sentiment
		const answerId = dropdown.dataset.answeridValue
		const formId = dropdown.dataset.formidValue
		const data = { tagging_id: parseInt(taggingId), sentiment_id: parseInt(sentimentId), id: parseInt(answerId), form_id: parseInt(formId) }
		const token = document.querySelector('meta[name="csrf-token"]').content
		fetch('/sentiments', {
			method: 'PUT',
			headers:  {
				"X-CSRF-Token": token,
				"Content-Type": "application/json",
				"Accept": "application/json"
			},
			body: JSON.stringify(data)
		})
		.then((response) => {
			return response.json();
		})
		.then((data) => {
			callback({value: data.id, text: data.name })
		});
		}
	negative(event, callback) {
		const dropdown = document.querySelector("#select-tags")
		const taggingId = this.negativeTargets[0].dataset.taggingid
		const sentimentId = this.negativeTargets[0].dataset.sentiment
		const answerId = dropdown.dataset.answeridValue
		const formId = dropdown.dataset.formidValue
		const data = { tagging_id: parseInt(taggingId), sentiment_id: parseInt(sentimentId), id: parseInt(answerId), form_id: parseInt(formId) }
		const token = document.querySelector('meta[name="csrf-token"]').content
		fetch('/sentiments', {
			method: 'PUT',
			headers:  {
				"X-CSRF-Token": token,
				"Content-Type": "application/json",
				"Accept": "application/json"
			},
			body: JSON.stringify(data)
		})
		.then((response) => {
			return response.json();
		})
		.then((data) => {
			callback({value: data.id, text: data.name })
		});
	}
}
