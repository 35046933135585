import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="button"
export default class extends Controller {
  static targets = ["button"]
  connect() {
    console.log("asd")
  }
  disable() {
    // setTimeout(() =>
    //   this.element.value = "Bingo!", 1000
    //   this.element.setAttribute("disabled", "")
    // )
  }
}
