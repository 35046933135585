import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="clipboard"
export default class extends Controller {
	static targets = [ "source" ]

  copy(event) {
    event.preventDefault()
    navigator.clipboard.writeText(this.sourceTarget.value)
		this.sourceTarget.innerText = "Copied"
		setTimeout(() => this.sourceTarget.innerText = "Copy Direct Link", 0.5 * 1000)
	}
}
