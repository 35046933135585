import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="nps"
export default class extends Controller {
  static targets = ["score", "sc", "feedback", "titleScore"]
  bg(event) {
    event.preventDefault()
    this.scoreTargets.forEach((el, i) => {
      el.classList.toggle("bg-gray-300", event.target == el );
      if(event.target == el){
        let innerElement = event.target.children;
        innerElement[0].checked = true;
      }
    })
		let elValue = document.querySelector('input[name="answer[score]"]:checked').value;
		this.titleScoreTarget.innerHTML = this.titleScoreTarget.innerHTML.replace(/\d|\[score\]/, elValue);
		this.feedbackTarget.classList.remove("hidden");
  }
}
