import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"

// Connects to data-controller="modal"
export default class extends Controller {
	static targets = ["container", "backdrop", "panel", "closeButton"]

	connect() {
		console.log("connected")
	}
  show() {
		console.log("asd")
    this.containerTarget.classList.remove("hidden")
    enter(this.backdropTarget)
  }

  hide() {
    Promise.all([
      leave(this.backdropTarget),
      leave(this.closeButtonTarget),
      leave(this.panelTarget)
    ]).then(() => {
      this.containerTarget.classList.add("hidden")
    })
  }
}
