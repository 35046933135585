import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="emaillink"
export default class extends Controller {
	static targets = ["links", "formId", "redirectionLink"]

	generate() {
		const formId = this.formIdTarget.value
		const linkValue = document.getElementById("lk").value
		const rtValue = document.getElementById("rt").value
		for (let i = 0; i < 11; i++) {
			this.linksTarget.insertAdjacentHTML("beforeend", `<li>https://nps.sanitygroup.com/forms/${formId}/email-embedded?score=${i}&order=[ORDERID]&products=[PRODUCTIDs]&email=[CUSTOMEREMAIL]&link=${encodeURIComponent(linkValue)}&rt=${rtValue}</li>`)
		}
  }

}
