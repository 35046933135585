import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select";
// Connects to data-controller="tom-select"

export default class extends Controller {
	static values = { companyid: Number}
  connect() {
		 const company = this.companyidValue
     const selectInput = document.getElementById('select-tags')
       if (selectInput) {
        // console.log('tom_select_controller connected');
        new TomSelect(selectInput, {
          plugins: {
            remove_button:{
              title:'Remove this item',
            }
          },
          onItemAdd:function(){
            this.setTextboxValue('');
            this.refreshOptions();
          },
          persist: false,
          create: function(input, callback) {
            const data = { name: input, company_id: company }
            const token = document.querySelector('meta[name="csrf-token"]').content
            fetch('/tags', {
              method: 'POST',
              headers:  {
                "X-CSRF-Token": token,
                "Content-Type": "application/json",
                "Accept": "application/json"
              },
              body: JSON.stringify(data)
            })
            .then((response) => {
              return response.json();
            })
            .then((data) => {
              callback({value: data.id, text: data.name })
            });
          },
					// render: {
					// 	item: function (data, escape) {
					// 		return '<div data-controller="hello">' +
					// 			'<span class="block">' + escape(data.name) + '</span>' +
					// 			'<div id="dropdownDots" class="z-10 bg-white divide-y divide-gray-100 rounded shadow w-44 dark:bg-gray-700 dark:divide-gray-600">' +
					// 				'<ul class="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownMenuIconButton">' +
					// 					'<li data-action="click->hello#positive">' +
					// 					'<span data-hello-target="positive" data-taggingid="'+ escape(data.value) +'" data-sentiment="0">Positive</span>' +
					// 					'</li>' +
					// 					'<li data-action="click->hello#neutral">' +
					// 					'<span data-hello-target="neutral" data-taggingid="'+ escape(data.value) +'" data-sentiment="1">Neutral</span>' +
					// 					'</li>' +
					// 					'<li data-action="click->hello#negative">' +
					// 					'<span data-hello-target="negative" data-taggingid="'+ escape(data.value) +'" data-sentiment="2">Negative</span>' +
					// 					'</li>' +
					// 				'</ul>' +
					// 			'</div>';
					// 	}
					// },
          onDelete: function(values) {
            console.log(values)
            return confirm(values.length > 1 ? 'Are you sure you want to remove these ' + values.length + ' items?' : 'Are you sure you want to remove "' + values[0].name + '"?');
          }
        });
       };
  }
  }
