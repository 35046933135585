import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"

// Connects to data-controller="dropdown"
export default class extends Controller {
	static targets = ["menu", "button"]

	toggleMenu(event) {
		var btn = event.target.dataset.buttonId
		var elems = this.menuTargets
		for (var i = elems.length - 1; i >= 0; i--) {
			if (elems[i].dataset.drpdwnId == btn){
				if (elems[i].classList.contains('hidden')) {
					enter(elems[i])
				} else {
					leave(elems[i])
				}
			}
		}
	}
}
