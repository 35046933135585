import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"

export default class extends Controller {
  static targets = [ "container", "backdrop","closeButton","modal" ]
	connect(){
		console.log("connected")
	}

	close() {
		Promise.all([
      leave(this.backdropTarget),
      leave(this.closeButtonTarget),
      leave(this.modalTarget),
    ]).then(() => {
			this.containerTarget.classList.add("hidden");
		});
	}

	show() {
		this.containerTarget.classList.remove("hidden");
		enter(this.backdropTarget);
    enter(this.closeButtonTarget);
    enter(this.modalTarget);
	}
  search() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.formTarget.requestSubmit()
    }, 200)
  }
}
