import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="filters"
export default class extends Controller {
  static targets = ["filter"];
	connect(){
		console.log("connected")
	}
  filter() {
    const url = `${window.location.pathname}?${this.params}`;

    Turbo.clearCache();
    Turbo.visit(url);
  }

  get params() {
    return this.filterTargets.map((t) => `${t.name}=${t.value}`).join("&");
  }
}
